<template lang="pug">
  .wrapper.i-wrap.cms-article-wrap(style="border-top:red 15px solid;")
    .section-left
    .section-mid
      h1 NOW EDITING
        router-link(:to="`/star/${id}`" target='_blank')
          i.fas.fa-external-link-alt
      .edit-top
        .poster-wrap
          img.poster(v-if="star.poster" :src="star.poster")
          .uploadPic
            input( type="file" accept="image/*" @change="onChange")
            VueCropper(
              v-if="imageUrl" 
              ref="cropper"
              :src="imageUrl"
              alt="Source Image"
              :aspectRatio="2/3"
              :viewMode="1"
              preview=".preview"
              style="width:300px;"
              )
          //- #preview(v-bind:class="{active : imageUrl}")
            img(v-if="poster && !imageUrl" v-bind:src="star.poster")
          //- input(v-if="imageUrl" type="text" v-model="posterDescription" placeholder="image description")
        .edit-deets
          .edit-section
            p.pointer(@click="tmdbFetch") Update from Tmdb
          .edit-section
            label Name (CH): 
            input(type="text" v-model="star.nameCH")
          .edit-section
            label Name (EN): 
            input(type="text" v-model="star.nameEN")
          .edit-section
            label Gender
            select(v-model="star.gender")
              option(value="male") male
              option(value="female") female
              option(value="other") other
              option(value="unknown") unknown
          .edit-section
            label Summary: 
            br
            textarea(type="text" v-model="star.summary")
          .edit-section
            label imdb id: {{star.imdbId}}
            //- input(type="text" v-model="star.imdbId")
          .edit-section
            label tmdb ID: {{star.tmdbId}}
            //- input(type="text" v-model="star.tmdbId")
          //- .edit-section
            label status: 
            select(v-model="star.status")
              option(v-for="status in statusList") {{status}}
          .edit-section
            label Date of Birth:
            .date  
              Datepicker(v-model="star.dob")
          .edit-section
            label Date of Death: 
            .date 
              Datepicker(v-model="star.dod")
            button(@click="star.dod = null") clear
          //- .edit-section
            label Original Language: 
            select(v-model="star.origLang")
              option(v-for="lang in origLangList") {{lang}}
          //- .article-input
            label Runtime: 
            input(v-model="star.runtime")
      //- .article-input
        p Select Genres
        .genres-wrap
          .genres(v-for="(genre, index) in genresEN") 
            span.genre.pointer(:ref="genre" @click="toggleGenre(genre,$event)") {{genresCH[index]}}
      
      .article-end(style="text-align:right")
        button.save(@click="saveStar()") Save Basic Info
      //- .article-input
        CmsCastCrewEdit(
          :id="id"
          :tmdbId="star.tmdbId"
          :type="'star'"
        )
      
    .section-right
      .edit-side
        h4 Keywords
        .keyword-wrap(v-for="(keyword, index) in keywords")
          i.pointer.fas.fa-minus-square(@click="delKeyword(index)")
          input(v-model="keywords[index]")
        span.pointer.add-keyword(@click="addKeyword")
          i.fas.fa-plus
          | &nbsp;Add keyword
      .edit-side
        h4 Add Video (from Youtube)
        label url:
        input(type="url" v-model="newVideo.link")
        select(v-model="newVideo.videoType")
          option(value="預告") 預告 
          option(value="花絮") 花絮
          option(value="訪談") 訪談
          option(value="評論") 評論
          option(value="其他") 其他
        button(@click="addVideo") Add
      //- .edit-side
        h4 Added videos
        .video-wrap(v-for="(video,index) in star.videos")
          p Video Link: {{video.link}}
          p Type: {{video.videoType}}
          img(v-bind:src="`https://img.youtube.com/vi/${video.link.split('?v=')[1]}/mqdefault.jpg`")
          button(@click="delVideo(index)") Delete
      .edit-side
        h4 Social Media
        p Facebook Link: 
        input(v-model="star.socialMedia.facebook")
        p Instagram Link: 
        input(v-model="star.socialMedia.instagram")
        p Official Website: 
        input(v-model="star.website")
      .article-side

</template>

<script>
// @ is an alias to /src
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import CmsCastCrewEdit from '@/views/cms/CmsCastCrewEdit'
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-vue';

import Datepicker from 'vuejs-datepicker';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';


export default {
  name: 'CmsStarNewEdit',
  props:{
    id:String,
  },
  components: {
    VueEditor,
    Datepicker,
    LeftBar,
    RightBar,
    VueCropper,
    CmsCastCrewEdit,
  },
  data(){
    return{
      newStar:true,
      star:{
        edited:true,
        genres:[],
        videos:[],
        socialMedia:{}
      },
      newVideo:{videoType:'預告'},
      
      keywords:[],
      poster:null,
      imageUrl:null,
      croppedImage:'',
      // origLangList:['da', 'hy', 'zh', 'nl', 'en', 'eo', 'fr', 'ka', 'de', 'el', 'it', 'ja', 'ko', 'ku', 'fa', 'pl', 'pt', 'ro', 'ru', 'es', 'sv', 'tr', 'ur'],
      // statusList:['Rumored', 'Planned', 'In Production', 'Post Production', 'Released', 'Cancelled'],
      
      file:null,
      customModulesForEditor: [{ alias: "imageDrop", module: ImageDrop }, { alias: "imageResize", module: ImageResize }],
      // genresEN:['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'],
      // genresCH:['動作','冒險','動畫','喜劇','犯罪','紀錄片','戲劇','家庭','幻想','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻小說','電視電影','驚悚片','戰爭','西方'],
      
    }
  },
  metaInfo: {
    title: '臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{
    tmdbFetch(){
      this.axios.post(`/cms/tmdbUpdate`,{type:`${this.star.model}`,tmdbId:`${this.star.tmdbId}`})
    },
    validateUrl(value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    },
    async addVideo(){
     
      var x = await this.validateUrl(this.newVideo.link)
      if(x==false){ 
        this.flash('invalid URL', 'warning')
      }else{
        this.star.videos.push(this.newVideo )
        this.newVideo = {videoType:'預告'};
      }
    },
    async delVideo(index){
      this.star.videos.splice(index,1);
    },
    // async toggleGenre(genre, event){
    //   event.target.classList.toggle('active')
    //   let popper = this.star.genres.indexOf(genre)
    //   if (popper != -1){
    //     this.star.genres.splice(popper, 1);
    //   }else{
    //     this.star.genres.push(genre)
    //   }
    // },
    async cropImage(){
      // get image data for post processing, e.g. upload or setting image src
      this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    async saveStar(){
      try{
        var payload = {...this.star};
        payload.keywords = this.keywords;
        payload.edited = true;
        if (this.newStar == true){
          
          var theStar = await this.axios.post('/cms/star/new/save',payload);
          if(theStar.data.error) throw('upload failed');
          else {
            this.flash('success','success')
            this.newStar = false;
            this.uploadImage(theStar.data._id);
            history.pushState({},null,`/user/star/${theStar.data._id}`)
          }
        }else{
          var editedStar = await this.axios.post(`/cms/star/${this.id}`,payload)
          await this.uploadImage(this.id);
          if(editedStar.status === 200) {
            this.flash('Star Updated', 'success')
          }
        }
      }catch(err){
        this.flash(err,'error')
      }
    },
    async uploadImage(starId){
      if(this.file){
        // this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
          this.$refs.cropper.getCroppedCanvas().toBlob(async(blob)=>{
          var formData = new FormData();
          this.croppedImage = new File([blob], "filename.jpg",{type:'image/jpeg'});
          formData.append('file',this.croppedImage)
          var uploadImg = await this.axios.post( `/cms/starUploadImage/${starId}`,formData,{headers: {'Content-Type': 'multipart/form-data'}})
          if(uploadImg.data) this.flash('Image Uploaded','success')
        },'image/jpeg');
        
      }
    },
    onChange(e) {
      this.imageUrl = null;
      const file = e.target.files[0]
      const reader = new FileReader();
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      this.file = file
      // this.imageUrl = URL.createObjectURL(file)

      reader.onload = (event) => {
        this.imageUrl = event.target.result;
        // rebuild cropperjs with the updated source
        // this.$refs.cropper.replace(event.target.result);
      };
      reader.readAsDataURL(file);

    },
    addKeyword(){
      this.keywords.push("")
    },
    delKeyword(index){
      if(this.keywords.length == 1){
        this.keywords.splice(index,1, '')
      }else{
        this.keywords.splice(index,1)
      }
    }
  },
  async created(){
    if (this.$route.name == 'StarNew'){ this.newStar = true;}
    else if (this.$route.name == 'StarEdit'){ 
      this.newStar = false;
      let response = await this.axios.get(`/cms/star/${this.id}`); 
      this.star = {...response.data.star}
      this.keywords = response.data.star.keywords;
      
      if(!response.data.star.socialMedia) this.star.socialMedia = {};
      // for(let i = 0; i < this.star.genres.length; i++){
      //   let theRef = this.star.genres[i]
      //   this.$refs[theRef][0].classList.toggle('active')
      // }
      
    }
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped >
.keyword-wrap{
  margin:10px 0;
}
.edit-top{
  display:flex;
  
}
.edit-side{
  margin:20px 0;
  padding:10px;
  border-bottom:1px solid grey;
}
.edit-section{
  margin:10px 0;
}
.poster{
  width:300px;
}
.edit-deets{
  margin-left:20px;
  width:calc(100% - 400px);
  textarea{
    width:100%;
    height:200px;
  }
}
.video-wrap{
  img{
    width:80%;
  }
}
.genres-wrap{
  display: flex;
  flex-wrap:wrap;
}
.genres{
  display:flex;
  
}
.genre{
  margin:5px;
  padding:5px 10px;
  border:solid 1px lightgrey;
  background:white;
  border-radius: 3px;
  transition:background .1s, color .1s;
  &.active{
    background:grey;
    color:white;
  }
}
</style>
